import { createApp } from "vue";

import App from "@/App";

const app = createApp(App);

import router from "@/router";
import store from "@/store";
import "@/services/axios";
import "bootstrap";
import "primeicons/primeicons.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "v-calendar/dist/style.css";

import PrimeVue from "primevue/config";
import VueFeather from "vue-feather";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler";

import moment from "moment";
moment.locale("ru");

app.config.globalProperties.$moment = moment;

Date.prototype.toISOString = function () {
  return moment(this).format("YYYY-MM-DD HH:mm:ss");
};

require("@/assets/css/theme.css");
require("@/assets/scss/_style.scss");
require("@/assets/scss/_theme_dark.scss");

let locale = "ru";
// if (localStorage.getItem("lang")) {
//   locale = localStorage.getItem("lang");
// } else if (navigator.language) {
//   const userLocale = navigator.language.split("-")[0];
//
//   if (["ru", "ro"].includes(userLocale)) {
//     locale = userLocale;
//   }
// }

const i18n = createI18n({
  locale: locale,
  pluralizationRules: {
    ru: function (choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
  messages: {
    ru: require("@/message/ru.json"),
    ro: require("@/message/ro.json"),
  },
});

app
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(moment)
  .use(i18n)
  .use(ToastService)
  .use(ConfirmationService)
  .component(VueFeather.name, VueFeather)
  .mount("#app");
